import Link from 'next/link';
import { memo } from 'react';
import Col from 'react-bootstrap/Col';

import styles from '@/styles/Components/FrontContainer/Footer.module.scss';

const FooterLogo = () => {
  return (
    <>
      <Col md="2">
        <div className="footer-widget">
          <div className={styles.footerTitle}>
            <Link href="/">
              <img loading="lazy" src="/assets/img/logo.webp" alt="Best Website for Adult in India" />
            </Link>
          </div>
          <div className={styles.footerContent}>
            <p> India's #1 Website for Adult Ads: Your Go-To for Escorts and Call Girls</p>
          </div>
          <div className={styles.footerSocial}>
            <ul className="social-link">
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/profile.php?id=61550723351361&mibextid=9R9pXO"
                  title="Facebook"
                >
                  <img loading="lazy" width="32" height="32" src="/assets/img/fb.webp" alt="Facebook" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://x.com/247torax/status/1700806093759508846?s=46"
                  title="Twitter"
                >
                  <img loading="lazy" width="32" height="32" src="/assets/img/twitter.webp" alt="Twitter" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://instagram.com/247torax?igshid=OGQ5ZDc2ODk2ZA=="
                  title="Instagram"
                >
                  <img loading="lazy" width="32" height="32" src="/assets/img/insta.webp" alt="Instagram" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Col>
    </>
  );
};

export default memo(FooterLogo);
